html {
  scroll-snap-type: y mandatory;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-snap-type: y mandatory;
}
